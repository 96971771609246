/* -----------------------------------------------------------------------------
  IMPORTS
*/

// Configuration
@import "base";

// Layout and components
@import "root";
@import "alert";
@import "badge";
@import "buttons";
@import "button-group";
@import "card";
@import "columns";
@import "dropdown";
@import "forms";
@import "grid";
@import "images";
@import "list-group";
@import "modal";
@import "nav";
@import "navbar";
@import "pagination";
@import "progress";
@import "spinners";
@import "tables";
@import "toasts";
@import "tooltip";
@import "transitions";
@import "type";
@import "utilities";
@import "data-table";
@import "fancybox";
@import "file-tree";

/* -----------------------------------------------------------------------------
  DEFAULTS
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* -----------------------------------------------------------------------------
  HEADER
*/

@keyframes color-animation {
  0% {
    background-position: 22% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.color-animation {
  //animation: color-animation 8s linear infinite;
  background: linear-gradient(90deg, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A);
  background-position: 22% 0;
  background-size: 300% 100%;
}

.color-header {
  @extend .color-animation;
  height: rem(14);
}

/* -----------------------------------------------------------------------------
  MAIN
*/

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  display: block;
  flex: 1 0 auto;
}

/* -----------------------------------------------------------------------------
  LOGIN
*/

.login {
  display: grid;
  grid-template-columns: 1fr;
}

.login-item {
  margin: 0 auto;
  min-width: $body-min-width;
  padding-left: rem($grid-min-gutter);
  padding-right: rem($grid-min-gutter);
  width: 100%;
}

.login-item-sm {
  max-width: rem(400);
}

.login-item-md {
  max-width: rem(550);
}

.login-item-lg {
  max-width: rem(900);
}

.login-header {
  display: grid;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, auto);
  }
}
