/* -----------------------------------------------------------------------------
  COLORS
*/

@import "project.json";

// Change default colors in project.json
$brand-1: #EE7F01 !default;
$brand-2: #006893 !default;

$white: #FFF;
$black: #000;

$gray-100: #F8F9FA !default;
$gray-200: #E9ECEF !default;
$gray-300: #DEE2E6 !default;
$gray-400: #CED4DA !default;
$gray-500: #ADB5BD !default;
$gray-600: #868E96 !default;
$gray-700: #495057 !default;
$gray-800: #343A40 !default;
$gray-900: #212529 !default;

$primary: $brand-1;
$secondary: $brand-2;
$tertiary: $brand-2;

$danger: #DC3545 !default;
$dark: $gray-900 !default;
$info: #17A2B8 !default;
$light: $gray-100 !default;
$success: #90AD25 !default;
$warning: #FFC107 !default;

$color-interval: 8%;

$colors: (
  "black": $black,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "danger": $danger,
  "dark": $dark,
  "info": $info,
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "warning": $warning,
  "white": $white,
);

// YIQ color contrast

$yiq-contrasted-threshold: 150;
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

/* -----------------------------------------------------------------------------
  UTILITIES
*/

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows: auto, hidden;

$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
);

// Embed

$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (4 3),
  (1 1),
);

// Sizing

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
);

// Spacing

$spacer: 8;

$spacers: (
  0: 0,
  1: rem($spacer),
  2: rem($spacer * 2),
  3: rem($spacer * 4),
  4: rem($spacer * 6),
  5: rem($spacer * 8),
);

/* -----------------------------------------------------------------------------
  BODY
*/

$body-bg-color: $white;
$body-color: $gray-900;

/* -----------------------------------------------------------------------------
  TYPOGRAPHY
*/

$small-font-size: 87.5%;

$min-font-size: 14;
$max-font-size: 15;

$h1-min-font-size: 24;
$h1-max-font-size: 29;
$h2-min-font-size: 20;
$h2-max-font-size: 24;
$h3-min-font-size: 16;
$h3-max-font-size: 21;
$h4-min-font-size: 14;
$h4-max-font-size: 16;
$h5-min-font-size: 14;
$h5-max-font-size: 16;

$input-font-size-sm: 14;
$input-font-size-lg: 18;
$input-font-size: 16;

$line-height-base: 1.5;

$sub-sup-font-size: 12;

$heading-color: $black;

$anchor-color: $tertiary;
$anchor-hover-color: darken($anchor-color, 10%);
$anchor-focus-color: $primary;

/* -----------------------------------------------------------------------------
  FORMS
*/

// Fieldset / Legend

$legend-margin-bottom: 8;
$legend-min-font-size: 14;
$legend-max-font-size: 16;

$input-control-indicator-size: 16;
$input-control-gutter: 8;

$input-border-width: 1;
$input-padding-x: 12;
$input-padding-y: 6;
$input-line-height: 1.5;
$input-height-border: $input-border-width * 2;
$input-height-inner: calc(#{$input-line-height * 1em} + #{rem($input-padding-y * 2)});
$input-height: calc(#{$input-line-height * 1em} + #{rem($input-padding-y * 2)} + #{rem($input-height-border)});

$input-bg-color: $white;
$input-border-color: $gray-500;
$input-border-focus: $primary;
$input-color: $gray-700;
$input-disabled-bg-color: $gray-200;
$input-focus-bg-color: $white;
$input-focus-box-shadow: 0 0 0 rem(3) rgba($input-border-focus, 0.5);
$input-focus-color: $gray-700;
$input-height-border: $input-border-width * 2;
$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;

// Select

$select-indicator-padding: 16;

// Text

$form-text-margin-top: 4;
$form-feedback-margin-top: 4;

// Range

$input-range-thumb-height: 16;
$input-range-thumb-width: 16;
$input-range-track-height: 8;
$input-range-track-width: 8;

$input-range-thumb-disabled-bg-color: $gray-500;
$input-range-track-bg-color: $gray-400;

// File

$input-file-text: (
  de: "Durchsuchen",
  en: "Browse",
);

// Button

$button-padding-y: 6;
$button-padding-x: 10;
$button-border-width: 1;

$button-pressed-bg-color: $tertiary;
$button-pressed-border-color: $tertiary;
$badge-pressed-bg-color: $white;

// Input group

$input-group-addon-bg-color: $gray-200;
$input-group-addon-border-color: $input-border-color;
$input-group-addon-color: $input-color;

/* -----------------------------------------------------------------------------
  PAGINATION
*/

$pagination-active-bg-color: $tertiary;
$pagination-active-border-color: darken($pagination-active-bg-color, 5%);
$pagination-active-color: $white;
$pagination-bg-color: $gray-100;
$pagination-border-color: $gray-400;
$pagination-color: $dark;
$pagination-disabled-bg-color: $white;
$pagination-disabled-border-color: $gray-700;
$pagination-disabled-color: $gray-600;
$pagination-disabled-color: $gray-600;
$pagination-focus-border-color: $tertiary;
$pagination-hover-bg-color: $gray-300;
$pagination-hover-border-color: $gray-500;
$pagination-hover-color: $dark;
$pagination-line-height: 1.5;
$pagination-padding-x: 12;
$pagination-padding-y: 7;

/* -----------------------------------------------------------------------------
  TABLE
*/

$table-bg-color: transparent;
$table-color: $body-color;
$table-border-color: $gray-500;
$table-border-width: 1;
$table-cell-padding-sm: $spacer;
$table-cell-padding: ($spacer * 2);

$table-head-bg-color: $gray-200;
$table-head-color: $gray-700;

$table-hover-bg-color-factor: 0.075;
$table-hover-bg-color: $gray-100;
$table-hover-color: $gray-900;

$table-striped-bg-color-factor: 0.05;
$table-striped-bg-color: $gray-200;
$table-striped-color: $body-color;

/* -----------------------------------------------------------------------------
  COLUMN
*/

$columns: 6;

/* -----------------------------------------------------------------------------
  BREAKPOINTS
*/

$body-min-width: rem(320);

$break-sm: 480;
$break-md: 768;
$break-lg: 992;
$break-xl: 1400;

$grid-breakpoints: (
  xs: 0,
  sm: rem($break-sm),
  md: rem($break-md),
  lg: rem($break-lg),
  xl: rem($break-xl),
);

/* -----------------------------------------------------------------------------
  GRID
*/

$grid-columns: 12;

$grid-min-gutter: 15;
$grid-max-gutter: 30;

$container-sm-max-width: $break-sm;
$container-md-max-width: $break-md;
$container-lg-max-width: $break-lg;
$container-xl-max-width: 1940;

$container-max-widths: (
  xs: 0,
  sm: rem($container-sm-max-width),
  md: rem($container-md-max-width),
  lg: rem($container-lg-max-width),
  xl: rem($container-xl-max-width),
);

/* -----------------------------------------------------------------------------
  COMPONENTS
*/

$border-radius: 5;
$border-width: 1;

// Alert

$alert-margin-bottom: 16;
$alert-padding-x: 12;
$alert-padding-y: 12;

$alert-bg-level: -10;
$alert-border-level: -10;
$alert-color-level: 6;

// Dropdowns

$dropdown-bg-color: $white;
$dropdown-border-color: $gray-400;
$dropdown-color: $body-color;
$dropdown-divider-bg-color: $gray-200;
$dropdown-divider-margin-y: $spacer / 2;
$dropdown-header-color: $gray-600;
$dropdown-item-padding-x: 24;
$dropdown-item-padding-y: 4;
$dropdown-link-active-bg-color: $white;
$dropdown-link-active-color: $body-color;
$dropdown-link-color: $gray-900;
$dropdown-link-disabled-color: $gray-600;
$dropdown-link-hover-bg-color: $white;
$dropdown-link-hover-color: $tertiary;
$dropdown-padding-y: 8;

// Card

$card-bg-color: $white;
$card-border-color: rgba($black, 0.125);
$card-border-radius: 8;
$card-border-width: $border-width;
$card-cap-bg-color: rgba($black, 0.03);
$card-spacer-x: ($spacer * 2);
$card-spacer-y: $spacer;

// Tooltip

$tooltip-arrow-color: $tertiary;
$tooltip-arrow-height: 8;
$tooltip-arrow-width: 12;
$tooltip-padding-x: 6;
$tooltip-padding-y: 3;
$tooltip-margin: 0;

// Progress bars

$progress-bar-bg-color: $secondary;
$progress-bar-color: $white;
$progress-bg-color: $gray-200;
$progress-height: 16;
$progress-bar-animation-timing: 1s linear infinite;

// Spinners

$spinner-color: $secondary;

$spinner-width: 32;
$spinner-height: $spinner-width;
$spinner-border-width: 4;
$spinner-animation-speed: 0.75s;

$spinner-width-sm: 16;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 3;

// Navs

$nav-link-padding-x: 16;
$nav-link-padding-y: 8;
$nav-link-disabled-color: $gray-700;

$nav-tabs-border-color: $gray-500;
$nav-tabs-border-width: 1;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-400 $gray-400 $nav-tabs-border-color;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg-color: $body-bg-color;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg-color;

// Navbar

$nav-link-padding-x: 16;
$nav-link-padding-y: 8;
$navbar-nav-link-padding-x: 12;
$navbar-padding-x: 0;
$navbar-padding-y: 8;

$navbar-active-color: $black;
$navbar-brand-color: $black;
$navbar-brand-hover-color: rgba($black, 0.9);
$navbar-color: $black;
$navbar-disabled-color: rgba($black, 0.3);
$navbar-hover-color: $tertiary;
$navbar-toggler-color: $dark;

// Toasts

$toast-font-size: 14;

$toast-background-color: $white;
$toast-border-color: $gray-400;
$toast-border-width: 0;
$toast-header-background-color: $gray-200;
$toast-header-border-color: $gray-400;
$toast-header-color: $gray-600;
$toast-max-width: 350;
$toast-padding-x: 8;
$toast-padding-y: 4;
