// stylelint-disable declaration-no-important

/* -----------------------------------------------------------------------------
  BASE STYLES
*/

.btn {
  @extend %btn;
}

.btn-lg {
  font-size: rem($input-font-size-lg);
  line-height: $line-height-base * 1.2;
  padding: rem($button-padding-y) rem($button-padding-x);
}

.btn-sm {
  font-size: rem($input-font-size-sm);
  line-height: $line-height-base * 0.8;
  padding: rem($button-padding-y) rem($button-padding-x);
}

/* -----------------------------------------------------------------------------
  ALTERNATE BUTTONS
*/

@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

/* -----------------------------------------------------------------------------
  LOADER
*/

.btn-loader {
  color: transparent !important;
  cursor: default;
  fill: transparent !important;
  pointer-events: none;
  position: relative;

  .spinner-border-sm {
    left: 50%;
    margin-left: rem(-$spinner-height-sm / 2);
    margin-top: rem(-$spinner-height-sm / 2);
    position: absolute;
    top: 50%;
  }
}
