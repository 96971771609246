%btn {
  background-color: transparent;
  border: rem($border-width) solid transparent;
  border-radius: rem($border-radius);
  color: $body-color;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: rem($input-font-size);
  line-height: $line-height-base;
  padding: rem($button-padding-y) rem($button-padding-x);
  text-align: center;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &.disabled,
  &:disabled {
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  &[aria-pressed="true"] {
    @include button-variant($button-pressed-bg-color, $button-pressed-border-color);

    .badge {
      @include badge-variant($badge-pressed-bg-color);
    }
  }
}
