/* -----------------------------------------------------------------------------
  RESETS
*/

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: inherit;
}


/* -----------------------------------------------------------------------------
  TABLE
*/

.table {
  --x-table-bg-color: #{$table-bg-color};
  --x-table-border-color: #{$table-border-color};
  --x-table-color: #{$table-color};
  --x-table-head-bg-color: #{$table-head-bg-color};
  --x-table-head-color: #{$table-head-color};
  --x-table-hover-bg-color: #{$table-hover-bg-color};
  --x-table-hover-color: #{$table-hover-color};
  --x-table-striped-bg-color: #{$table-striped-bg-color};
  --x-table-striped-color: #{$table-striped-color};

  border-bottom: rem($table-border-width) solid var(--x-table-border-color);
  color: var(--x-table-color);
  margin-bottom: rem($spacer * 2);
  max-width: 100%;
  width: 100%;

  > :not(caption) > * > * {
    background-color: var(--x-table-bg-color);
    border-top: rem($table-border-width) solid var(--x-table-border-color);
    padding: rem($table-cell-padding);
    vertical-align: top;

    &:first-child {
      padding-left: rem($table-cell-padding * 2);
    }

    &:last-child {
      padding-right: rem($table-cell-padding * 2);
    }
  }

  > thead > * > th {
    border-bottom: rem($table-border-width * 2) solid var(--x-table-border-color);
    border-top: 0;
    position: relative;
    vertical-align: bottom;
  }

  > tfoot > * td {
    border-top: rem($table-border-width * 2) solid var(--x-table-border-color);
  }
}

.table-sm {
  > :not(caption) > * > * {
    padding: rem($table-cell-padding-sm);

    &:first-child {
      padding-left: rem($table-cell-padding-sm * 2);
    }

    &:last-child {
      padding-right: rem($table-cell-padding-sm * 2);
    }
  }
}

/* -----------------------------------------------------------------------------
  TABLE: LAYOUT
*/

.table-layout-fixed {
  table-layout: fixed;
}

/* -----------------------------------------------------------------------------
  TABLE: BORDERLESS
*/

.table-borderless {
  border: 0;

  > :not(caption) > * > *,
  > thead > * > th {
    border: 0;
  }
}

/* -----------------------------------------------------------------------------
  TABLE: STRIPING
*/

.table-striped {
  > tbody > tr:nth-of-type(even) > * {
    background-color: var(--x-table-striped-bg-color);
    color: var(--x-table-striped-color);
  }
}

/* -----------------------------------------------------------------------------
  TABLE: HOVER
*/

.table-hover {
  > tbody > tr:hover > * {
    background-color: var(--x-table-hover-bg-color);
    color: var(--x-table-hover-color);
  }
}

/* -----------------------------------------------------------------------------
  TABLE: BACKGROUND
*/

@mixin table-variant($state, $table-bg-color, $table-border-color: null) {
  .table-#{$state} {
    $table-color: color-yiq($table-bg-color);
    $table-hover-bg-color: mix($table-color, $table-bg-color, percentage($table-hover-bg-color-factor));
    $table-striped-bg-color: mix($table-color, $table-bg-color, percentage($table-striped-bg-color-factor));

    --x-table-bg-color: #{$table-bg-color};
    --x-table-color: #{$table-color};
    --x-table-hover-bg-color: #{$table-hover-bg-color};
    --x-table-hover-color: #{color-yiq($table-hover-bg-color)};
    --x-table-striped-bg-color: #{$table-striped-bg-color};
    --x-table-striped-color: #{color-yiq($table-striped-bg-color)};

    @if $table-border-color != null {
      --x-table-border-color: #{$table-border-color};
    }
  }
}

@each $color, $value in $colors {
  @include table-variant($color, color-level($value, -9), color-level($value, -6));
}


/* -----------------------------------------------------------------------------
  TABLE: RESPONSIVE
*/

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint);
  $infix: breakpoint-infix($next);

  @include media-breakpoint-down($breakpoint) {
    .table-responsive#{$infix} {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;

      > .table-bordered {
        border: 0;
      }
    }
  }
}
