:root {
  @each $color, $value in $colors {
    --x-#{$color}: #{$value};
  }

  --x-border-radius: #{rem($border-radius)};

  // Spacing
  --x-spacer: #{rem($spacer)};

  // Input
  --x-input-border-width: #{rem($input-border-width)};
}
