/* -----------------------------------------------------------------------------
  FILE TREE
*/

.file-tree {
  border: var(--x-input-border-width) solid var(--x-gray-500);
  border-radius: var(--x-border-radius);
  height: rem(208);
  margin: var(--x-spacer) 0;
  overflow-y: auto;
  padding: var(--x-spacer);
  position: relative;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: rem(24);
    position: relative;

    &::before {
      background: var(--x-primary);
      content: "";
      display: block;
      height: rem(24);
      left: 0;
      line-height: rem(28);
      mask-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z' /></svg>"));
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: rem(24);
      padding: 0 0 0 rem(26);
      position: absolute;
      top: rem(3);
      white-space: nowrap;
      width: rem(24);
    }

    &.wait {
      &::before {
        mask-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z'/></svg>"));
      }
    }

    &.directory {
      &::before {
        mask-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z'/></svg>"));
      }
    }

    &.expanded {
      &::before {
        mask-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z'/></svg>"));
      }
    }
  }

  a {
    color: var(--x-gray-900);
    line-height: rem(28);
    margin-left: rem(4);
    padding: rem(2) rem(6);
    text-decoration: none;

    &:hover {
      color: var(--x-primary);
    }

    &.selected {
      background: var(--x-gray-400);
      color: var(--x-gray-900);
    }
  }
}

.is-invalid {
  ~ .file-tree {
    border-color: var(--x-danger);
  }
}
