// stylelint-disable declaration-no-important

/* -----------------------------------------------------------------------------
  ALERT
*/

@mixin alert-variant($background, $border, $color) {
  background-color: $background;
  border-color: $border;
  color: $color;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }

  &.alert-dismissible {
    .close {
      fill: darken($color, 10%);
    }
  }
}

/* -----------------------------------------------------------------------------
  BACKGROUND
*/

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}

/* -----------------------------------------------------------------------------
  BREAKPOINTS
*/

@mixin media-breakpoint-up($breakpoint) {
  $min: breakpoint-min($breakpoint);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  $max: breakpoint-max($breakpoint);

  @if $max {
    @media (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @content;
  }
}

/* -----------------------------------------------------------------------------
  BUTTONS
*/

@mixin button-variant($background, $border) {
  $hover-background: darken($background, 5%);
  $hover-border: darken($border, 10%);
  background-color: $background;
  border-color: $border;
  color: color-yiq($background);
  fill: color-yiq($background);

  &:focus,
  &.focus {
    background-color: $hover-background;
    border-color: $hover-border;
    box-shadow: 0 0 0 rem(3) rgba($hover-border, 0.5);
    color: color-yiq($hover-background);
    fill: color-yiq($hover-background);
  }

  &:hover {
    background-color: $hover-background;
    border-color: $hover-border;
    color: color-yiq($hover-background);
    fill: color-yiq($hover-background);
  }

  &.disabled,
  &:disabled {
    background-color: $gray-400;
    border-color: $gray-400;
    color: color-yiq($gray-100);
    fill: color-yiq($gray-100);

    &:focus,
    &.focus {
      background-color: $gray-400;
      border-color: $hover-border;
      box-shadow: 0 0 0 rem(3) rgba($hover-border, 0.5);
      color: color-yiq($gray-100);
      fill: color-yiq($gray-100);
    }
  }

  .spinner-border {
    --x-spinner-color: #{color-yiq($background)};
  }
}

/* -----------------------------------------------------------------------------
  BADGES
*/

@mixin badge-variant($bg-color) {
  background-color: $bg-color;
  color: color-yiq($bg-color);

  @at-root a#{&} {
    &:hover {
      background-color: darken($bg-color, 10%);
      color: color-yiq($bg-color);
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 rem(3) rgba($bg-color, 0.5);
      outline: 0;
    }
  }
}

/* -----------------------------------------------------------------------------
  DROPDOWN
*/

@mixin caret-down {
  border-bottom: 0;
  border-left: rem(4) solid transparent;
  border-right: rem(4) solid transparent;
  border-top: rem(4) solid;
}

@mixin caret-up {
  border-bottom: rem(4) solid;
  border-left: rem(4) solid transparent;
  border-right: rem(4) solid transparent;
  border-top: 0;
}

@mixin caret-right {
  border-bottom: rem(4) solid transparent;
  border-left: rem(4) solid;
  border-right: 0;
  border-top: rem(4) solid transparent;
}

@mixin caret-left {
  border-bottom: rem(4) solid transparent;
  border-right: rem(4) solid;
  border-top: rem(4) solid transparent;
}

@mixin caret($direction: down) {
  &::after {
    content: "";
    display: inline-block;
    margin-left: rem(6);
    vertical-align: rem(4);

    @if $direction == down {
      @include caret-down;
    } @else if $direction == up {
      @include caret-up;
    } @else if $direction == right {
      @include caret-right;
    }
  }

  @if $direction == left {
    &::after {
      display: none;
    }

    &::before {
      content: "";
      display: inline-block;
      margin-right: rem(4);
      vertical-align: rem(4);
      @include caret-left;
    }
  }

  &:empty::after {
    margin-left: 0;
  }
}

/* -----------------------------------------------------------------------------
  FONT SIZE
*/

@mixin font-size($min-font-size, $max-font-size: 0) {
  font-size: rem($min-font-size);

  @if ($max-font-size != 0) {
    @media (min-width: rem($break-sm)) {
      font-size: calc(#{rem($min-font-size)} + #{($max-font-size - $min-font-size) / 16} * (100vw - #{rem($break-sm)}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
    }

    @media (min-width: rem($break-xl)) {
      font-size: rem($max-font-size);
    }
  }
}

/* -----------------------------------------------------------------------------
  GRID
*/

@mixin make-container() {
  --x-min-gutter-x: #{rem($grid-min-gutter / 2)};
  --x-max-gutter-x: #{rem($grid-max-gutter / 2)};
  --x-fluid-increase-gutter-x: #{($grid-max-gutter / 2 - $grid-min-gutter / 2) / 16} * (100vw - #{rem($break-sm)}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)});
  --x-fluid-gutter-x: calc((var(--x-min-gutter-x) * -1) - var(--x-fluid-increase-gutter-x));

  margin: 0 auto;
  min-width: $body-min-width;
  padding-left: var(--x-min-gutter-x);
  padding-right: var(--x-min-gutter-x);
  width: 100%;

  @media (min-width: rem($break-sm)) {
    padding-left: calc(var(--x-min-gutter-x) + var(--x-fluid-increase-gutter-x));
    padding-right: calc(var(--x-min-gutter-x) + var(--x-fluid-increase-gutter-x));
  }

  @media (min-width: rem($break-xl)) {
    padding-left: var(--x-max-gutter-x);
    padding-right: var(--x-max-gutter-x);
  }
}

@mixin make-container-max-widths() {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($divider: 2) {
  --x-min-gutter-x: #{rem($grid-min-gutter / $divider)};
  --x-max-gutter-x: #{rem($grid-max-gutter / $divider)};
  --x-fluid-increase-gutter-x: #{($grid-max-gutter / $divider - $grid-min-gutter / $divider) / 16} * (100vw - #{rem($break-sm)}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)});
  --x-fluid-gutter-x: calc((var(--x-min-gutter-x) * -1) - var(--x-fluid-increase-gutter-x));

  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-left: calc(var(--x-min-gutter-x) * -1);
  margin-right: calc(var(--x-min-gutter-x) * -1);

  @media (min-width: rem($break-sm)) {
    margin-left: var(--x-fluid-gutter-x);
    margin-right: var(--x-fluid-gutter-x);
  }

  @media (min-width: rem($break-xl)) {
    margin-left: calc(var(--x-max-gutter-x) * -1);
    margin-right: calc(var(--x-max-gutter-x) * -1);
  }
}

@mixin make-col-ready() {
  flex-shrink: 0;
  max-width: 100%;
  padding-left: var(--x-min-gutter-x);
  padding-right: var(--x-min-gutter-x);
  position: relative;
  width: 100%;

  @media (min-width: rem($break-sm)) {
    padding-left: calc(var(--x-min-gutter-x) + var(--x-fluid-increase-gutter-x));
    padding-right: calc(var(--x-min-gutter-x) + var(--x-fluid-increase-gutter-x));
  }

  @media (min-width: rem($break-xl)) {
    padding-left: var(--x-max-gutter-x);
    padding-right: var(--x-max-gutter-x);
  }
}

@mixin make-col($size) {
  flex: 0 0 auto;
  width: percentage($size / $grid-columns);
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size) {
  $num: $size / $grid-columns;
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-grid-columns() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
      .col#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @include make-col($i);
        }
      }

      @for $i from 0 through ($grid-columns - 1) {
        @if not ($infix == "" and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i);
          }
        }
      }
    }
  }
}

/* -----------------------------------------------------------------------------
  ICON
*/

@mixin icon-variant($parent, $color) {
  #{$parent} {
    fill: $color !important;
  }
}

/* -----------------------------------------------------------------------------
  LIST
*/

@mixin list-unstyled {
  list-style: none;
  padding-left: 0;
}

/* -----------------------------------------------------------------------------
  NAV DIVIDER
*/

@mixin nav-divider($color: $nav-divider-color, $margin-y: $nav-divider-margin-y) {
  border-top: rem($border-width) solid $color;
  height: 0;
  margin: rem($margin-y) 0;
  overflow: hidden;
}

/* -----------------------------------------------------------------------------
  RESET TEXT
*/

@mixin reset-text {
  font-style: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: $line-height-base;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
}
