/* -----------------------------------------------------------------------------
  SPINNERS
*/

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --x-spinner-color: #{$spinner-color};

  animation: spinner-border $spinner-animation-speed linear infinite;
  border: rem($spinner-border-width) solid var(--x-spinner-color);
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  height: rem($spinner-height);
  vertical-align: text-bottom;
  width: rem($spinner-width);
}

.spinner-border-sm {
  border-width: rem($spinner-border-width-sm);
  height: rem($spinner-height-sm);
  width: rem($spinner-width-sm);
}
